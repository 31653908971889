/* eslint-disable max-len */
/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

// Packages
import React from 'react';
import { graphql } from 'gatsby';

// UI local components
import PageLayout from '../shared/PageLayout';
import Seo from '../shared/Seo';
import TemplatePage from '../page-components/template-page';

// Assets
import BANNER_IMG from '../images/precription/banner.png';
import LUXOR_BUILDING_IMG from '../images/precription/batiment.png';

// Styles
import '../page-styles/index.scss';

/* -------------------------------------------------------------------------- */
/*                                    Page                                    */
/* -------------------------------------------------------------------------- */

function PrescriptionPage() {
  /* ------------------------------- CONSTANSTS ----------------------------- */
  const SITE_TITLE = 'LUXOR TECHNOLOGY AND TRADE | prescription';
  // eslint-disable-next-line operator-linebreak
  const SITE_DESCRIPTION =
    'Luxor technolgie & Trade est une société Tunisienne Spécialisée dans le développement des solutions techniques pour la construction. ';

  /* ******************************** RENDERING ******************************* */
  return (
    <PageLayout>
      <Seo title={SITE_TITLE} description={SITE_DESCRIPTION} />
      <TemplatePage
        pageTitle="prescription"
        parag1="Nous vous accompagnons, au sein du groupe Luxor, dans vos projets, pour vous offrir, de la phase de l'étude à celle de l'exécution, des solutions adaptées à vos besoins et à vos d​éfis."
        parag2="Nous ne nous contentons pas des produits commerciaux standards et nous vous présentons des solutions spécifiques afin de répondre aux plus pointues de vos exigences."
        bannerImg={BANNER_IMG}
        image={LUXOR_BUILDING_IMG}
        imgAlt="luxor-building"
        path="prescription"
        titleBreadcrumbs="prescription"
      />
    </PageLayout>
  );
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: {
        ns: { in: ["HomePage", "Common"] }
        language: { eq: $language }
      }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

export default PrescriptionPage;
